import { Grid, Typography, Tooltip, Link as MuiLink } from '@mui/material';
import { Stack } from '@mui/system';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import React from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { CompanyProfileCell, ActionDrawerCell, CompanyCell, FolioInvoiceProfileCell, AmountToCollectCell } from '../components/cells';
import CompanyBlacklistComponent from '../components/cells/CompanyBlacklistComponent';
import { DateWithDaysDiffCell, StatusSummaryStepper } from '../components/dataGridCells';
import CollectionActions from '../components/drawer/CollectionActions';
import DrawerHeader from '../components/drawer/DrawerHeader';
import { formatRut } from '../helpers';
import { useIsMobile } from '../hooks';
import OperationalAlertsArray from '../components/cells/OperationalAlertsArray';
import CreditLineMovementHistory from '../components/cells/CreditLineMovementHistory';
import useWidth from './document-columns-width';

const useInvoiceLucilaCustomColumns = (showDicom = true, receiverType = 'receiver', debtsShowType = null) => {
  const isMobile = useIsMobile();
  const {
    amountWidth,
  } = useWidth();
  return [
    {
      field: 'dateToPay',
      type: 'date',
      headerName: isMobile ? 'Vencimiento' : 'Fecha de vencimiento',
      ...GRID_DATE_COL_DEF,
      width: 130,
      renderCell: (params) => (
        <DateWithDaysDiffCell
          date={params.row.dateToPay}
          status={params.row.status}
          useColors
        />
      ),
    },
    {
      field: 'paymentDate',
      headerName: isMobile ? 'Fecha' : 'Fecha Recaudada',
      ...GRID_DATE_COL_DEF,
      width: 130,
      renderCell: ({ row }) => (
        <DateWithDaysDiffCell
          date={row.paymentDate}
          useColors
        />
      ),
    },
    {
      field: 'folio',
      headerName: 'Folio',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      minWidth: 90,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <FolioInvoiceProfileCell invoice={row} AlertsArray={OperationalAlertsArray} />
      ),
    },
    {
      field: 'company_profile',
      type: 'string',
      headerName: 'Emisor',
      renderCell: ({ row }) => (
        <CompanyProfileCell
          masterEntity={row.company.masterEntity}
          showDicom={showDicom}
        />
      ),
      sortable: true,
      minWidth: 100,
      flex: 2,
    },
    {
      field: 'receiver_sortable',
      type: 'string',
      headerName: 'Receptor',
      renderCell: ({ row }) => (
        <CompanyProfileCell
          masterEntity={row.receiver}
          showDicom={showDicom}
          type={receiverType}
          debtsShowType={debtsShowType}
        />
      ),
      sortable: true,
      minWidth: 150,
      flex: 2,
    },
    {
      field: 'company_MasterEntity_Name',
      headerName: 'Emisor ajosi',
      type: 'string',
      minWidth: 150,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row.company.masterEntity.name} placement="top" arrow>
          <Grid width="100%" alignItems="flex-start" justifyContent="center">
            <Typography variant="body2" width="inherit" noWrap>
              {row.company.masterEntity.name}
            </Typography>
            <Typography variant="caption" width="inherit" noWrap>
              {row.company.masterEntity.displayNationalIdentifier}
            </Typography>
          </Grid>
        </Tooltip>
      ),
    },
    {
      field: 'receiver_Name',
      headerName: 'Receptor',
      type: 'string',
      minWidth: 150,
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="flex-start" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <MuiLink
              component={Link}
              to={`/app/invoice-debtor/${params.row.receiver.id}`}
              color="primary"
              variant="body2"
              align="left"
              rel="noopener noreferrer"
              underline="hover"
            >
              {params.row.receiver.name}
            </MuiLink>
            <CompanyBlacklistComponent
              masterEntity={params.row.receiver}
              disableDisplay
            />
          </Stack>
          {!isMobile && (
            <Typography variant="subtitle1">
              {params.row.receiver.displayNationalIdentifier}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'receiver_Name_No_Link',
      headerName: 'Receptor',
      type: 'string',
      flex: 1,
      renderCell: ({ row }) => (
        <CompanyCell
          company={row.receiver}
        />
      ),
    },
    {
      field: 'siiStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: 120,
      type: 'singleSelect',
      align: 'center',
      renderCell: (params) => (
        <StatusSummaryStepper
          invoice={params.row}
        />
      ),
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      minWidth: 90,
      filterable: false,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <ActionDrawerCell
          title={params.row.receiver.name}
          subtitle={formatRut(params.row.receiver.rut)}
          actions={params.row.collectionManager?.actions || []}
          headerComponent={DrawerHeader}
          lastActionDate
          contentComponent={CollectionActions}
        />
      ),
    },
    {
      field: 'amountToCollect',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <AmountToCollectCell
          row={params.row}
        />
      ),
    },
    {
      field: 'amountWithIvaAndCreditNote',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth,
      valueGetter: (params) => params.row.amountWithIva.amount,
      valueSetter: (params) => ({
        ...params.row,
        amountWithIva: { ...params.row.amountWithIva, amount: params.value },
      }),
      renderCell: ({ row }) => (
        <AmountToCollectCell row={row} />
      ),
    },
    {
      field: 'amountUsedInEntityLine',
      headerName: 'Uso línea',
      type: 'string',
      sortable: false,
      width: 100,
      renderCell: ({ row }) => <CreditLineMovementHistory row={row} />,
    },
  ];
};

const customPriceDifferenceColumn = () => [
  {
    field: 'priceDifference',
    headerName: 'Dif. precio',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    width: 120,
    renderCell: (params) => (
      <Stack alignItems="flex-start" justifyContent="center" width="100%">
        <Typography variant="body2" align="left" noWrap width="inherit">
          $
          <CountUp separator="." end={params.row.priceDifference} duration={1} />
        </Typography>
      </Stack>
    ),
  },
];

export { useInvoiceLucilaCustomColumns, customPriceDifferenceColumn };
