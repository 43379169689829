import React from 'react';
import {
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { useIsMobile } from '../hooks';
import { useWidth } from '.';
import { formatMoney, getTimeDiffText } from '../helpers';
import { StatusSummaryStepper } from '../components/dataGridCells';
import { CompanyCell } from '../components/cells';

const useFakeInvoicePreColumns = (customInvoiceColumns = []) => {
  const isMobile = useIsMobile();
  const {
    amountWidth,
    dateWidth,
    iconSize,
    statusWidth,
  } = useWidth();
  const invoiceDefaultPreColumns = [
    {
      field: 'folio',
      headerName: 'Folio',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      width: isMobile ? 60 : 65,
      renderCell: (params) => (
        <Stack alignItems="flex-start" justifyContent="center" width="100%">
          <Typography variant="body2" align="left" noWrap width="inherit" color="primary">
            {params.row.folio}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'dteType_Code',
      headerName: 'Tipo',
      type: 'singleSelect',
      valueOptions: [{ label: 'Factura Electronica', value: 33 },
        { label: 'Factura Exenta Electronica', value: 34 },
        { label: 'Nota de Credito Electronica', value: 61 },
        { label: 'Nota de Debito Electronica', value: 56 }],
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography
            variant="body2"
          >
            {params.row.dteType.name}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'receiver_Name',
      headerName: 'Receptor',
      type: 'string',
      flex: 1,
      renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
    },
    {
      field: 'dateIssued',
      width: dateWidth,
      type: 'date',
      headerName: 'Emisión',
      filterable: false,
      renderCell: (params) => (
        params.row.dateIssued
          ? (
            <Grid container justifyContent="center" alignItems="flex-start" direction="column">
              <Typography variant="body2">{params.row.dateIssued.format(isMobile ? 'DD MMM' : 'DD-MM-YYYY')}</Typography>
              {!isMobile && (
              <Typography variant="caption">{getTimeDiffText(params.row.dateIssued)}</Typography>
              )}
            </Grid>
          ) : <></>
      ),
    },
    {
      field: 'amountWithIva',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth,
      renderCell: (params) => (
        <Stack alignItems="flex-start" justifyContent="center" width="100%">
          <Typography variant="body2" align="left" noWrap width="inherit">
            ${formatMoney(params.row.amountWithIva)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'siiStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: statusWidth,
      type: 'singleSelect',
      align: 'center',
      renderCell: ({ row }) => (
        <StatusSummaryStepper
          invoice={row}
        />
      ),
    },
    {
      field: 'chat',
      headerName: 'Chat',
      sortable: false,
      filterable: false,
      width: iconSize,
      renderCell: () => <ChatIcon />,
    },
  ];
  const invoicePreColumns = invoiceDefaultPreColumns.map(
    (defaultColumn) => customInvoiceColumns.find(
      (customColumn) => customColumn.field === defaultColumn.field,
    ) || defaultColumn,
  );
  const newPreColumns = customInvoiceColumns.filter(
    (customColumn) => !invoiceDefaultPreColumns.map(
      (defaultColumn) => defaultColumn.field,
    ).includes(customColumn.field),
  );
  return [...invoicePreColumns, ...newPreColumns];
};

export default useFakeInvoicePreColumns;
