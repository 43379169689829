export const invoiceHeaders = [
  'simpleFolio',
  'dteType_Code',
  'dateIssued',
  'receiver_Name',
  'amountWithIva',
  'offerMonthlyRate',
  'offerDefaultRate',
  'factoringStatus',
];

export const orderingHeaders = [
  'orderNumber',
  'purchaser_Name',
  'publicationDate',
  'totalAmount',
  'orderingoffer_OrderingPaymentAmount',
  'orderingoffer_OrderingMonthlyRate',
  'orderingoffer_OrderingRetentionRate',
  'orderingoffer_InvoiceIssuedDate',
  'orderingOperationActionOffer',
];
