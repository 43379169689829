const useCompanyContactColumns = () => [
  {
    field: 'name',
    headerName: 'Nombre',
    type: 'string',
    width: 80,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'position',
    headerName: 'Cargo',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'phoneNumber',
    headerName: 'Teléfono',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 2,
  },
  {
    field: 'email',
    headerName: 'Correo',
    type: 'string',
    width: 140,
    align: 'center',
    editable: true,
    flex: 2,
  },
];

export default useCompanyContactColumns;
